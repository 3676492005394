import { useContext, useState } from "react";
import icon9 from "../../../../../../../assets/icon9.png";
import { HomeContext } from "../../../../../Context/HomeContext";

export default function FontColorDrop() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { selectedColor, setSelectedColor, colors } = useContext(HomeContext);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    setDropdownOpen(false);
  };

  return (
    <>
      <div className="TextTab-edit-r2-fontColor-drop">
        <div
          className="TextTab-edit-r2-fontColor-drop-btn"
          onClick={toggleDropdown}
        >
          <div
            className="color-display"
            style={{ backgroundColor: selectedColor }}
          ></div>
          <img
            src={icon9}
            alt=""
            style={{
              transform: dropdownOpen ? "rotate(.5turn)" : null,
            }}
          />
        </div>
        {dropdownOpen && (
          <div className="TextTab-edit-r2-fontColor-drop-list">
            {colors.map((color, index) => (
              <div
                key={index}
                className="TextTab-edit-r2-fontColor-drop-list-item"
                style={{
                  border: `${
                    selectedColor === color ? "2px" : "1px"
                  } solid ${
                    selectedColor === color ? "#000000" : "#BFBFBF"
                  }`,
                }}
                onClick={() => handleColorSelect(color)}
              >
                <p
                  style={{
                    backgroundColor: color,
                    border :  "1px solid #BFBFBF"
                  }}
                ></p>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
