// mobile-size

import { useContext} from "react";
import icon9 from "../.../../../../../../../../assets/icon9.png";
import { HomeContext } from "../../../../../Context/HomeContext";

export default function FontOffcanvas() {
  const { fontFamily, setFontFamily } = useContext(HomeContext);
  const fonts = [
    "Roboto",
    "Afrah",
    "Alexbrush",
    "Amazone",
    "Ananda",
    "Aristotelicasmallcaps",
    "Barcelony",
    "Bilboswashcaps",
    "Cabin",
    "Cardo",
    "Crimsonpro",
    "Exo2",
    "Fabulous",
    "Freehand521",
    "Gafta",
    "Heartandsoul",
    "Hello",
    "Hijrnotes",
    "Josephsophia",
    "Karla",
    "Kaufmann",
    "Lhandw",
    "Lobster",
    "Lora",
    "Lovelyhome",
    "Monotypecorsva",
    "Montserrat",
    "Montserrat2",
    "Neuton",
    "Opensans",
    "Pacifico",
    "Poppins",
    "Prozalibre",
    "Quicksand",
    "Waterfall",
  ];

  return (
    <>
      <div className="FontOffcanvas">
        <button
          className="btn FontOffcanvas-button"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasBottom"
          aria-controls="offcanvasBottom"
        >
          {fontFamily}
          <img src={icon9} alt="icon9" />
        </button>

        <div
          className="offcanvas offcanvas-bottom FontOffcanvas-content"
          tabIndex="-1"
          id="offcanvasBottom"
          aria-labelledby="offcanvasBottomLabel"
        >
          <div className="offcanvas-header FontOffcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasBottomLabel">
              Font Type
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body small FontOffcanvas-body">
            {fonts.map((font , index) => {
              return (
                <div
                  className="FontOffcanvas-body-item"
                  style={{ borderTop: "0.5px solid #9C9C9C" }}
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  onClick={() => setFontFamily(font)}
                  key={index}
                >
                  {font}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
