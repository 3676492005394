import { createContext, useEffect, useState } from "react";

export const HomeContext = createContext();

export default function HomeContextProvider(props) {
  const colors = [
    "#000000",
    "#393C41",
    "#9C9C9C",
    "#FFFFFF",
    "#384B70",
    "#507687",
    "#FCFAEE",
    "#B8001F",
    "#A1D6B2",
    "#CEDF9F",
    "#F1F3C2",
    "#E8B86D",
    "#A28B55",
    "#86AB89",
    "#CBE2B5",
    "#E7FBE6",
    "#FF204E",
    "#A0153E",
    "#5D0E41",
    "#00224D",
    "#606C5D",
    "#FFF4F4",
    "#F7E6C4",
    "#F1C376",
  ];

  const [menu, setMenu] = useState("typeRose");
  const [colorRoseSelected, setColorRoseSelected] = useState(
    "/textures/petalos/base color_albedo/blu1.png"
  );

  const [colorCode, setColorCode] = useState(
    "#E22202"
  );


  const [fontFamily, setFontFamily] = useState("Roboto");
  const [fontFamilyPath, setFontFamilyPath] = useState(
    "/CustomizerFont/Roboto.ttf"
  );
  const [selectedColor, setSelectedColor] = useState(colors[0]);
  const [fontSize, setFontSize] = useState(48);
  const [text, setText] = useState("");
  const [material, setMaterial] = useState("acrylic");
  const [boxSelected, setBoxSelected] = useState(
    "/static/media/ECUADORIAN_CARTOON.c75d4626a2a1ededca5e.png"
  );

  const [loading, setLoading] = useState({});
  const [loadingMain, setLoadingMain] = useState(false);

  const contextValue = {
    menu,
    setMenu,
    colorRoseSelected,
    setColorRoseSelected,
    colorCode,
    setColorCode,
    selectedColor,
    setSelectedColor,
    colors,
    fontFamily,
    setFontFamily,
    fontSize,
    setFontSize,
    text,
    setText,
    fontFamilyPath,
    setFontFamilyPath,
    material,
    setMaterial,
    boxSelected,
    setBoxSelected,
    loading,
    setLoading,
    loadingMain,
    setLoadingMain,
  };
  return (
    <HomeContext.Provider value={contextValue}>
      {props.children}
    </HomeContext.Provider>
  );
}
