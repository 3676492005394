import { useRef } from "react";
import { useState } from "react";
import icon7 from "../../../../../../assets/icon7.png";

export default function ImageTab() {
  const [image, setImage] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);

  const handleImageChange = (e) => {
    let file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleRemove = () => {
    setImage(null);
  }

  return (
    <>
      <div
        className={`ImageTab ${dragActive ? "drag-active" : ""}`}
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragLeave={handleDrag}
      >
        {image ? (
          <div className="ImageTab-uploaded">
            <img src={image} alt="پیش‌نمایش" />
            <div className="ImageTab-uploaded-closeBtn" onClick={handleRemove}>
              <i className="bi bi-x"></i>
            </div>
          </div>
        ) : (
          <div
            className="ImageTab-drag"
            onDrop={handleDrop}
            onClick={handleClick}
          >
            <img src={icon7} alt="image1" />
            <span className="ImageTab-drag-title">Drag file here or</span>
            <span className="ImageTab-drag-browseBtn">Brows Files</span>
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              onChange={handleImageChange}
            />
          </div>
        )}
      </div>
    </>
  );
}
