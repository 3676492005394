import { useContext } from "react";
import { HomeContext } from "../../Context/HomeContext";
import Lottie from "lottie-react";
import animationFile from "./Animation - 1728492191126.json";

export default function Loading() {
  const { loading } = useContext(HomeContext);

  return (
    <>
      <div className="Loading" style={{
        display : loading ? "block" : "none"
      }}>
        <Lottie
          animationData={animationFile}
          loop={true}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    </>
  );
}
