import { useState } from "react";
import TextTab from "./TextTab/TextTab";
import ImageTab from "./ImageTab/ImageTab";
import VideoTab from "./VideoTab/VideoTab";

export default function TextPanel() {
  const [activeTab, setActiveTab] = useState("text");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="DesignRose-text">
        <div className="DesignRose-text-tab">
          <div
            onClick={() => handleTabClick("text")}
            className="DesignRose-text-tab-item"
            style={{
              borderBottom: activeTab === "text" ? "2px solid black" : "none",
              fontWeight: activeTab === "text" ? "bold" : "normal",
              color: activeTab === "text" ? "#171A20" : "#5C5E62",
            }}
          >
            Text
          </div>
          <div
            onClick={() => handleTabClick("image")}
            className="DesignRose-text-tab-item"
            style={{
              borderBottom: activeTab === "image" ? "2px solid black" : "none",
              fontWeight: activeTab === "image" ? "bold" : "normal",
              color: activeTab === "image" ? "#171A20" : "#5C5E62",
            }}
          >
            Image
          </div>
          <div
            onClick={() => handleTabClick("clipart")}
            className="DesignRose-text-tab-item"
            style={{
              borderBottom:
                activeTab === "clipart" ? "2px solid black" : "none",
              fontWeight: activeTab === "clipart" ? "bold" : "normal",
              color: activeTab === "clipart" ? "#171A20" : "#5C5E62",
            }}
          >
            Clipart
          </div>
        </div>

        <div className="DesignRose-text-content">
          {activeTab === "text" && <TextTab />}

          {activeTab === "image" && <ImageTab />}
          {activeTab === "clipart" && <VideoTab />}
        </div>
      </div>
    </>
  );
}
