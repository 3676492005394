// mobile-size

import { useContext} from "react";
import icon9 from "../../../../../../../assets/icon9.png";
import { HomeContext } from "../../../../../Context/HomeContext";

export default function FontColorOffcanvas() {
  const { selectedColor, setSelectedColor, colors } = useContext(HomeContext);

  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  return (
    <>
      <div className="FontColorOffcanvas">
        <button
          className="btn FontColorOffcanvas-button"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasColor"
          aria-controls="offcanvasColor"
        >
          <div style={{ backgroundColor: selectedColor }}></div>
          <img src={icon9} alt="" />
        </button>

        <div
          className="offcanvas offcanvas-bottom FontColorOffcanvas-content"
          tabIndex="-1"
          id="offcanvasColor"
          aria-labelledby="offcanvasBottomLabel"
        >
          <div className="offcanvas-header FontColorOffcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasBottomLabel">
              Font Color
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>

          <div className="offcanvas-body small FontColorOffcanvas-body">
            <div className="FontColorOffcanvas-body-container">
              {colors.map((color, index) => (
                <div
                  key={index}
                  className="FontColorOffcanvas-body-item"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  style={{
                    border: `${selectedColor === color ? "2px" : "1px"} solid ${
                      selectedColor === color ? "#000000" : "#BFBFBF"
                    }`,
                  }}
                  onClick={() => handleColorSelect(color)}
                >
                  <p
                    style={{
                      backgroundColor: color,
                      border: "1px solid #BFBFBF",
                    }}
                  ></p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
