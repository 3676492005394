import { useContext, useState } from "react";
import rose3 from "../../../../assets/rose3.png";
import rose4 from "../../../../assets/rose4.png";
import TextPanel from "./TextPanel/TextPanel";
import Material from "./Material/Material";
import Price from "./Price/Price";
import { HomeContext } from "../../Context/HomeContext";
import Boxes from "./Boxes/Boxes";
import Quabtity from "./Quantity/Quantity";

import gry2 from "../../../../assets/base-color-albedo/gry2.png";
import pea1 from "../../../../assets/base-color-albedo/pea1.png";
import pin1 from "../../../../assets/base-color-albedo/pin1.png";
import pin2 from "../../../../assets/base-color-albedo/pin2.png";
import pin3 from "../../../../assets/base-color-albedo/pin3.png";
import pur1 from "../../../../assets/base-color-albedo/pur1.png";
import red1 from "../../../../assets/base-color-albedo/red1.png";
import red2 from "../../../../assets/base-color-albedo/red2.png";
import red3 from "../../../../assets/base-color-albedo/red3.png";
import red4 from "../../../../assets/base-color-albedo/red4.png";
import vio1 from "../../../../assets/base-color-albedo/vio1.png";
import whi1 from "../../../../assets/base-color-albedo/whi1.png";
import whi2 from "../../../../assets/base-color-albedo/whi2.png";
import whi3 from "../../../../assets/base-color-albedo/whi3.png";
import yel1 from "../../../../assets/base-color-albedo/yel1.png";

export default function DesignPanel() {
  const [roseColor, setRoseColor] = useState("blu1");
  const { menu, setMenu } = useContext(HomeContext);
  const [roseType, setRoseType] = useState("Short-Stem-Rose");
  const { setColorRoseSelected } = useContext(HomeContext);
  const { setColorCode } = useContext(HomeContext);

  const ROSE_COLORS = [
    { code: "blu1", hex: "#50CFD7", texture: "/textures/colors textures/blu_02_50CFD7.png" },
    { code: "blu2", hex: "#0700C5", texture: "/textures/colors textures/blu_03_0700C5 .png" },
    { code: "blu3", hex: "#7DB4D4", texture: "/textures/colors textures/blu_89_7DB4D4.png" },
    { code: "blu4", hex: "#95989c", texture: "/textures/colors textures/gry_02_95989c.png" },
    { code: "blu5", hex: "#FFA360", texture: "/textures/colors textures/pea_99_FFA360 .png" },
    { code: "blu6", hex: "#FFBBE2", texture: "/textures/colors textures/pin_04_FFBBE2.png" },
    { code: "blu7", hex: "#F919AE", texture: "/textures/colors textures/pin_07_F919AE .png" },
    { code: "blu8", hex: "#FFBCD2", texture: "/textures/colors textures/pin_99_FFBCD2.png" },
    { code: "blu9", hex: "#A1008E", texture: "/textures/colors textures/pur_02_A1008E .png" },

    { code: "blu10", hex: "#790800", texture: "/textures/colors textures/red_01_790800.png" },
    { code: "blu11", hex: "#E22202", texture: "/textures/colors textures/red_02_E22202.png" },
    { code: "blu12", hex: "#DBA8DC", texture: "/textures/colors textures/vio_03_DBA8DC.png" },
    { code: "blu13", hex: "#FFF7F7", texture: "/textures/colors textures/whi_01_FFF7F7 .png" },

    { code: "blu14", hex: "#ECE1C2", texture: "/textures/colors textures/whi_04_ECE1C2.png" },
    { code: "blu15", hex: "#FFD52F", texture: "/textures/colors textures/yel_02_FFD52F.png" },
  ];
  

  function RoseColor({ color, isActive, onClick }) {
    return (
      <div
        style={{
          backgroundColor: color.hex,
          border: `${isActive ? "2px" : "1px"} solid ${isActive ? "#000000" : color.hex}`,
        }}
        onClick={onClick}
      />
    );
  }




    const handleRoseColorChange = (color) => {
      setRoseColor(color.code);
      setColorRoseSelected(color.texture);
      setColorCode(color.hex);
    };


  

  return (
    <>
      <div className="DesignPanel-container col-lg-9">
        <section className="DesignPanel-tools" id="accordionEx">
          <h3 className="DesignPanel-tools-title">Design Your Own</h3>

          <div className="DesignPanel-tools-typeRose">
            <div className="DesignPanel-tools-typeRose-container">
              <div
                className="DesignPanel-tools-typeRose-title"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#ChooseType"
                aria-expanded="false"
                aria-controls="ChooseType"
                onClick={() => {
                  if (menu !== "typeRose") {
                    setMenu("typeRose");
                  } else {
                    setMenu(null);
                  }
                }}
              >
                <span
                  style={{
                    fontWeight: menu === "typeRose" ? "700" : "400",
                  }}
                >
                  Choose Type
                </span>
                <i
                  className="bi bi-chevron-up"
                  style={{
                    transform:
                      menu !== "typeRose" ? "rotate(0.5turn)" : "rotate(1turn)",
                  }}
                ></i>
              </div>
              <div
                className={`DesignPanel-tools-typeRose-content collapse ${menu === "typeRose" ? "show" : "null"
                  }`}
                id="ChooseType"
                data-bs-parent="#accordionEx"
              >
                <section className="Rose-type ">
                  <div
                    className="Rose-type-item"
                    onClick={() => {
                      setRoseType("Short-Stem-Rose");
                    }}
                  >
                    <div
                      className={`Rose-type-item-image ${roseType === "Short-Stem-Rose" ? "ActiveRose" : ""
                        }`}
                    >
                      <img src={rose3} alt="rose3" />
                    </div>
                    <span
                      className="Rose-type-item-text"
                      style={{
                        fontWeight: menu === "typeRose" ? "600" : "400",
                      }}
                    >
                      Short Stem Rose
                    </span>
                  </div>
                  <div
                    className="Rose-type-item"
                    onClick={() => {
                      setRoseType("Petal");
                    }}
                  >
                    <div
                      className={`Rose-type-item-image ${roseType === "Petal" ? "ActiveRose" : ""
                        }`}
                    >
                      <img src={rose4} alt="rose4" />
                    </div>
                    <span className="Rose-type-item-text">Petal</span>
                  </div>
                </section>
              </div>
            </div>
          </div>




          <div className="DesignPanel-tools-colorRose">
          <div className="DesignPanel-tools-colorRose-container">
            <div
              className="DesignPanel-tools-colorRose-title"
              onClick={() => setMenu(menu !== "colorRose" ? "colorRose" : "typeRose")}
            >
              <span style={{ fontWeight: menu === "colorRose" ? "700" : "400" }}>
                Choose Color
              </span>
              <i
                className="bi bi-chevron-up"
                style={{
                  transform: menu !== "colorRose" ? "rotate(0.5turn)" : "rotate(1turn)",
                }}
              />
               </div>
            <div
              className={`DesignPanel-tools-colorRose-content collapse ${menu === "colorRose" ? "show" : ""}`}
              id="colorRose"
              data-bs-parent="#accordionEx"
            >
              <section className="Rose-color">
                {ROSE_COLORS.map((color) => (
                  <RoseColor
                    key={color.code}
                    color={color}
                    isActive={roseColor === color.code}
                    onClick={() => handleRoseColorChange(color)}

                    />
                    ))}
                  </section>
                </div>
              </div>
            </div>
        






          {/* <div className="DesignPanel-tools-colorRose">
            <div className="DesignPanel-tools-colorRose-container">
              <div
                className="DesignPanel-tools-colorRose-title"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#colorRose"
                aria-expanded="false"
                aria-controls="colorRose"
                onClick={() => {
                  if (menu !== "colorRose") {
                    setMenu("colorRose");
                  } else {
                    setMenu("typeRose");
                  }
                }}
              >
                <span
                  style={{
                    fontWeight: menu === "colorRose" ? "700" : "400",
                  }}
                >
                  Choose Color
                </span>
                <i
                  className="bi bi-chevron-up"
                  style={{
                    transform:
                      menu !== "colorRose"
                        ? "rotate(0.5turn)"
                        : "rotate(1turn)",
                  }}
                ></i>
              </div>
              <div
                className={`DesignPanel-tools-colorRose-content collapse ${menu === "colorRose" ? "show" : "null"
                  }`}
                id="colorRose"
                data-bs-parent="#accordionEx"
              >
                <section className="Rose-color">
                  <div

                    style={{
                      backgroundColor: '#50CFD7' ,
                      border: `${roseColor === "blu1" ? "2px" : "1px"} solid ${roseColor === "blu1" ? "#000000" : "#50CFD7"
                        }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "blu1") {
                        setRoseColor("blu1");
                        setColorRoseSelected(
                          "/textures/colors textures/blu_02_50CFD7.png"
                        );
                        setColorCode("#50CFD7")
                      }
                    }}
                  >

                  </div>
                  <div
                    style={{
                      backgroundColor: '#0700C5' ,
                      border: `${roseColor === "blu2" ? "2px" : "1px"} solid ${roseColor === "blu2" ? "#000000" : "#0700C5"
                        }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "blu2") {
                        setRoseColor("blu2");
                        setColorRoseSelected(
                          "/textures/colors textures/blu_03_0700C5 .png"
                        );
                        setColorCode("#0700C5")

                      }
                    }}
                  >
                  </div>
                  <div
                    style={{
                      backgroundColor: '#7DB4D4' ,
                      border: `${roseColor === "blu3" ? "2px" : "1px"} solid ${roseColor === "blu3" ? "#000000" : "#7DB4D4"
                        }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "blu3") {
                        setRoseColor("blu3");
                        setColorRoseSelected(
                          "/textures/colors textures/blu_89_7DB4D4.png"
                        );
                        setColorCode("#7DB4D4")

                      }
                    }}
                  >
                  </div>
                  <div
                    style={{
                      backgroundColor: '#95989c' ,
                      border: `${roseColor === "blu4" ? "2px" : "1px"} solid ${roseColor === "blu4" ? "#000000" : "#95989c"
                        }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "blu4") {
                        setRoseColor("blu4");
                        setColorRoseSelected(
                          "/textures/colors textures/gry_02_95989c.png"
                        );
                        setColorCode("#95989c")
                      }
                    }}
                  >
                  </div>
                  <div
                    style={{
                      backgroundColor: '#FFA360' ,
                      border: `${roseColor === "blu5" ? "2px" : "1px"} solid ${roseColor === "blu5" ? "#000000" : "#FFA360"
                        }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "blu5") {
                        setRoseColor("blu5");
                        setColorRoseSelected(
                          "/textures/colors textures/pea_99_FFA360 .png"
                        );
                        setColorCode("#FFA360")
                      }
                    }}
                  >
                  </div>
                  <div
                    style={{
                      backgroundColor: '#FFBBE2' ,
                      border: `${roseColor === "gry1" ? "2px" : "1px"} solid ${roseColor === "gry1" ? "#000000" : "#FFBBE2"
                        }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "gry1") {
                        setRoseColor("gry1");
                        setColorRoseSelected(
                          "/textures/colors textures/pin_04_FFBBE2.png"
                        );
                        setColorCode("#FFBBE2")
                      }
                      }
                    }
                  >
                  </div>
                  <div
                    style={{
                      border: `${roseColor === "gry2" ? "2px" : "1px"} solid ${roseColor === "gry2" ? "#000000" : "#BFBFBF"
                        }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "gry2") {
                        setRoseColor("gry2");
                        setColorRoseSelected(
                          "/textures/colors textures/blu_03_0700C5 .png"
                        );
                      }
                    }}
                  >
                    <img src={gry2} alt="gry2" />
                  </div>
                  <div
                    style={{
                      border: `${roseColor === "pea1" ? "2px" : "1px"} solid ${roseColor === "pea1" ? "#000000" : "#BFBFBF"
                        }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "pea1") {
                        setRoseColor("pea1");
                        setColorRoseSelected(
                          "/textures/colors textures/blu_03_0700C5 .png"
                        );
                      }
                    }}
                  >
                    <img src={pea1} alt="pea1" />
                  </div>
                  <div
                    style={{
                      border: `${roseColor === "pin1" ? "2px" : "1px"} solid ${roseColor === "pin1" ? "#000000" : "#BFBFBF"
                        }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "pin1") {
                        setRoseColor("pin1");
                        setColorRoseSelected(
                          "/textures/colors textures/blu_03_0700C5 .png"
                        );
                      }
                    }}
                  >
                    <img src={pin1} alt="pin1" />
                  </div>
                  <div
                    style={{
                      border: `${roseColor === "pin2" ? "2px" : "1px"} solid ${roseColor === "pin2" ? "#000000" : "#BFBFBF"
                        }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "pin2") {
                        setRoseColor("pin2");
                        setColorRoseSelected(
                          "/textures/colors textures/blu_03_0700C5 .png"
                        );
                      }
                    }}
                  >
                    <img src={pin2} alt="pin2" />
                  </div>
                  <div
                    style={{
                      border: `${roseColor === "pin3" ? "2px" : "1px"} solid ${roseColor === "pin3" ? "#000000" : "#BFBFBF"
                        }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "pin3") {
                        setRoseColor("pin3");
                        setColorRoseSelected(
                          "/textures/petalos/base color_albedo/pin3.png"
                        );
                      }
                    }}
                  >
                    <img src={pin3} alt="pin3" />
                  </div>
                  <div
                    style={{
                      border: `${roseColor === "pur1" ? "2px" : "1px"} solid ${roseColor === "pur1" ? "#000000" : "#BFBFBF"
                        }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "pur1") {
                        setRoseColor("pur1");
                        setColorRoseSelected(
                          "/textures/petalos/base color_albedo/pur1.png"
                        );
                      }
                    }}
                  >
                    <img src={pur1} alt="pur1" />
                  </div>
                  <div
                    style={{
                      border: `${roseColor === "red1" ? "2px" : "1px"} solid ${roseColor === "red1" ? "#000000" : "#BFBFBF"
                        }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "red1") {
                        setRoseColor("red1");
                        setColorRoseSelected(
                          "/textures/petalos/base color_albedo/red1.png"
                        );
                      }
                    }}
                  >
                    <img src={red1} alt="red1" />
                  </div>
                  <div
                    style={{
                      border: `${roseColor === "red2" ? "2px" : "1px"} solid ${roseColor === "red2" ? "#000000" : "#BFBFBF"
                        }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "red2") {
                        setRoseColor("red2");
                        setColorRoseSelected(
                          "/textures/petalos/base color_albedo/red2.png"
                        );
                      }
                    }}
                  >
                    <img src={red2} alt="red2" />
                  </div>
                  <div
                    style={{
                      border: `${roseColor === "red3" ? "2px" : "1px"} solid ${roseColor === "red3" ? "#000000" : "#BFBFBF"
                        }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "red3") {
                        setRoseColor("red3");
                        setColorRoseSelected(
                          "/textures/petalos/base color_albedo/red3.png"
                        );
                      }
                    }}
                  >
                    <img src={red3} alt="red3" />
                  </div>
                  <div
                    style={{
                      border: `${roseColor === "red4" ? "2px" : "1px"} solid ${roseColor === "red4" ? "#000000" : "#BFBFBF"
                        }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "red4") {
                        setRoseColor("red4");
                        setColorRoseSelected(
                          "/textures/petalos/base color_albedo/red4.png"
                        );
                      }
                    }}
                  >
                    <img src={red4} alt="red4" />
                  </div>
                  <div
                    style={{
                      border: `${roseColor === "vio1" ? "2px" : "1px"} solid ${roseColor === "vio1" ? "#000000" : "#BFBFBF"
                        }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "vio1") {
                        setRoseColor("vio1");
                        setColorRoseSelected(
                          "/textures/petalos/base color_albedo/vio1.png"
                        );
                      }
                    }}
                  >
                    <img src={vio1} alt="vio1" />
                  </div>
                  <div
                    style={{
                      border: `${roseColor === "whi1" ? "2px" : "1px"} solid ${roseColor === "whi1" ? "#000000" : "#BFBFBF"
                        }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "whi1") {
                        setRoseColor("whi1");
                        setColorRoseSelected(
                          "/textures/petalos/base color_albedo/whi1.png"
                        );
                      }
                    }}
                  >
                    <img src={whi1} alt="whi1" />
                  </div>
                  <div
                    style={{
                      border: `${roseColor === "whi2" ? "2px" : "1px"} solid ${roseColor === "whi2" ? "#000000" : "#BFBFBF"
                        }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "whi2") {
                        setRoseColor("whi2");
                        setColorRoseSelected(
                          "/textures/petalos/base color_albedo/whi2.png"
                        );
                      }
                    }}
                  >
                    <img src={whi2} alt="whi2" />
                  </div>
                  <div
                    style={{
                      border: `${roseColor === "whi3" ? "2px" : "1px"} solid ${roseColor === "whi3" ? "#000000" : "#BFBFBF"
                        }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "whi3") {
                        setRoseColor("whi3");
                        setColorRoseSelected(
                          "/textures/petalos/base color_albedo/whi3.png"
                        );
                      }
                    }}
                  >
                    <img src={whi3} alt="whi3" />
                  </div>
                  <div
                    style={{
                      border: `${roseColor === "yel1" ? "2px" : "1px"} solid ${roseColor === "yel1" ? "#000000" : "#BFBFBF"
                        }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "yel1") {
                        setRoseColor("yel1");
                        setColorRoseSelected(
                          "/textures/petalos/base color_albedo/yel1.png"
                        );
                      }
                    }}
                  >
                    <img src={yel1} alt="yel1" />
                  </div>
                </section>
              </div>
            </div>
          </div>
 */}




          <div className="DesignPanel-tools-designRose">
            <div
              className="DesignPanel-tools-designRose-container"
              id="accordionDesignRose"
            >
              <div
                className="DesignPanel-tools-designRose-title"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#designRose"
                aria-expanded="false"
                aria-controls="designRose"
                onClick={() => {
                  if (menu !== "designRose") {
                    setMenu("designRose");
                  } else {
                    setMenu("typeRose");
                  }
                }}
              >
                <span
                  style={{
                    fontWeight: menu === "designRose" ? "700" : "400",
                  }}
                >
                  Design Your Rose
                </span>
                <i
                  className="bi bi-chevron-up"
                  style={{
                    transform:
                      menu !== "designRose"
                        ? "rotate(0.5turn)"
                        : "rotate(1turn)",
                  }}
                ></i>
              </div>
              <div
                className={`DesignPanel-tools-designRose-content collapse ${menu === "designRose" ? "show" : "null"
                  }`}
                id="designRose"
                data-bs-parent="#accordionEx"
              >
                <TextPanel />
              </div>
            </div>
          </div>

          <div className="DesignPanel-tools-packaging">
            <div className="DesignPanel-tools-packaging-container">
              <div
                className="DesignPanel-tools-packaging-title"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Packaging"
                aria-expanded="false"
                aria-controls="Packaging"
                onClick={() => {
                  if (menu !== "packaging") {
                    setMenu("packaging");
                  } else {
                    setMenu("typeRose");
                  }
                }}
              >
                <span
                  style={{
                    fontWeight: menu === "packaging" ? "700" : "400",
                  }}
                >
                  Packaging
                </span>
                <i
                  className="bi bi-chevron-up"
                  style={{
                    transform:
                      menu !== "packaging"
                        ? "rotate(0.5turn)"
                        : "rotate(1turn)",
                  }}
                ></i>
              </div>
              <div
                className={`DesignPanel-tools-packaging-content collapse ${menu === "packaging" ? "show" : "null"
                  }`}
                id="Packaging"
                data-bs-parent="#accordionEx"
              >
                <Quabtity />
                <Material />
                <Boxes />
              </div>
            </div>
          </div>

          <div className="DesignPanel-price-mob">
            <Price />
          </div>
        </section>

        <section className="DesignPanel-price">
          <Price />
        </section>
      </div>
    </>
  );
}
