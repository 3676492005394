import { useContext, useEffect, useRef, useState } from "react";
import logo from "../../../../assets/logo.png";
import bIcon1 from "../../../../assets/bIcon1.png";
import bIcon2 from "../../../../assets/bIcon2.png";
import bIcon3 from "../../../../assets/bIcon3.png";
import * as THREE from "three";
import { RoomEnvironment } from "three/addons/environments/RoomEnvironment.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { HomeContext } from "../../Context/HomeContext";
import { normalize } from "three/src/math/MathUtils";

export default function CanvasPart() {
  const canvasRef = useRef();
  const modelRef = useRef([]);
  const sceneRef = useRef();
  const cameraRef = useRef();
  const orbitRef = useRef();
  const rendererRef = useRef();
  const firstLoad = useRef(true);
  const [loadModel, setLoadModel] = useState(false);

  let newBaseColorTexture ;
  let newNormalMapTexture,newNormalMapBaseRose;
  let newHeightMapTexture ;
  let newRoughnessMapTexture ;


  const {
    colorCode,
    colorRoseSelected,
    text,
    fontFamilyPath,
    fontFamily,
    fontSize,
    selectedColor,
    loadingMain,
    setLoadingMain,
  } = useContext(HomeContext);

  let mainOption;

  const logCameraPosition = () => {
    if (cameraRef.current) {
      const { x, y, z } = cameraRef.current.position;
      console.log(`Camera Position - x: ${x}, y: ${y}, z: ${z}`);
    }
  };

  // function waitForFontToLoad(fontFamily) {
  //   return new Promise((resolve) => {
  //     document.fonts.load(`10pt ${fontFamily}`).then(resolve);
  //   });
  // }

  function waitForFontToLoad(fontFamily) {
    return new Promise((resolve, reject) => {
      document.fonts
        .load(`10pt ${fontFamily}`)
        .then(() => {
          console.log("Font successfully loaded:", fontFamily);
          resolve();
        })
        .catch((error) => {
          console.error("Error loading font:", error);
          reject(error);
        });
    });
  }

  const loadText = (mainText) => {
    console.log("loadText");
    let fontSize1 = fontSize;
    let fontFamily1 = fontFamily;
    let selectedColor1 = selectedColor;

    async function loadFont(fontFamilyPath, callback) {
      const newStyle = document.createElement("style");
      newStyle.appendChild(
        document.createTextNode(`
        @font-face {
          font-family: '${fontFamily1}';
          src: url('${fontFamilyPath}');
        }
      `)
      );
      document.head.appendChild(newStyle);

      const testCanvas = document.createElement("canvas");
      const testCtx = testCanvas.getContext("2d");
      testCtx.font = `${fontSize1} ${fontFamily1}`;

      // Wait for the font to load
      try {
        await waitForFontToLoad(fontFamily1);
        console.log("Font loaded successfully:", fontFamily1);
        callback();
      } catch (error) {
        console.error("Error while loading font:", error);
      }

      callback();
    }

    function createTextTexture(text1, options = {}) {
      const {
        width = 1000,
        height = 1000,
        // fillStyle = "#000",
        // fontSize = 48, // Font size can be customized
        // fontFamily = "Roboto" , // You can change the font family here
        textAlign = "center",
        textBaseline = "middle",
        bold = false,
        italic = false,
      } = options;

      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");

      ctx.clearRect(0, 0, width, height);

      ctx.translate(0, height); // Move the origin to the bottom-left corner
      ctx.scale(1, -1); // Flip the canvas along the Y-axis

      ctx.font = `${italic ? "italic" : ""} ${
        bold ? "bold" : ""
      } ${fontSize1}px ${fontFamily1}`;
      ctx.fillStyle = selectedColor1;
      ctx.textAlign = textAlign;
      ctx.textBaseline = textBaseline;

      ctx.fillText(text1, width / 2, height / 2);

      const texture = new THREE.CanvasTexture(canvas);
      return texture;
    }

    loadFont(fontFamilyPath, () => {
      const flowerMesh2 = modelRef.current.getObjectByName("textlayer");
      if (flowerMesh2) {
        const textTexture2 = createTextTexture(mainText, mainOption);
        flowerMesh2.material = new THREE.MeshBasicMaterial({
          map: textTexture2,
          transparent: true,
        });
      }

      flowerMesh2.material.needsUpdate = true;
    });
  };

  function updateMeshBaseColorTexture(baseColorTexturePath,colorCode) {
    const targetMeshes = ["petal"];

    const textureLoader = new THREE.TextureLoader();

    // Load the new base color texture
    const newBaseColorTexture = textureLoader.load(baseColorTexturePath, () => {
      newBaseColorTexture.flipY = false;
      newBaseColorTexture.needsUpdate = true; // Ensure texture is updated after loading
    });

    modelRef.current.traverse((child) => {
      // if (child.isMesh && targetMeshes.includes(child.name)) {
      if (child.isMesh) {
        const material = child.material;


        if (material) {

          if (child.name.includes("petal")) {
           
            const color = colorCode.startsWith('#') ? colorCode : '#' + colorCode;
            material.color.set(color); 
            material.map = newBaseColorTexture; 
            material.normalmap = newNormalMapTexture; 
            material.roughnessMap=newRoughnessMapTexture
            material.needsUpdate = true;
          }
          if (child.name.includes("base")) {

            const color = colorCode.startsWith('#') ? colorCode : '#' + colorCode;
            material.color.set(color); 

            // material.map ={color:"0x"+colorCode.split('#')[0]} ; 
            material.normalmap = newNormalMapBaseRose; 
            material.roughnessMap=newRoughnessMapTexture
            material.needsUpdate = true;

          }
        }

      }
      });


  }

  const init = () => {
    // Create scene


    const textureLoader = new THREE.TextureLoader();

    // Load your textures (update paths to your textures)
     newBaseColorTexture = textureLoader.load('/textures/principal textures/red_02_E22202.png');
     newNormalMapBaseRose = textureLoader.load('/textures/principal textures/normal_base rosa.png');
     newNormalMapTexture = textureLoader.load('/textures/principal textures/Petal_Rose_Red_Normal.png');
     newRoughnessMapTexture = textureLoader.load('/textures/principal textures/rose_roughness.png');

    const scene = new THREE.Scene();
    // scene.background = new THREE.Color(0xf0f0f0);
    sceneRef.current = scene;

    // Set up camera
    const camera = new THREE.PerspectiveCamera(
      45,
      canvasRef.current.clientWidth / canvasRef.current.clientHeight,
      0.01,
      1000
    );
    // camera.position.set(-0.242, 0.284, 0.2395);
    camera.position.set(
      -0.3518734965497957,
      0.23914218048842792,
      0.0804560829307368
    );

    // camera.lookAt(0, 0, 0)
    cameraRef.current = camera;

    // Set up renderer
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setClearColor(0x000000, 0); // Make the background transparent
    renderer.setSize(
      canvasRef.current.clientWidth,
      canvasRef.current.clientHeight
    );

    // renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMapping = THREE.NeutralToneMapping;
    renderer.toneMappingExposure = 0.7;

    rendererRef.current = renderer;
    canvasRef.current.appendChild(renderer.domElement);

    // Set up OrbitControls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.enableZoom = true;
    orbitRef.current = controls;
    controls.addEventListener("change", logCameraPosition);

    const size = 10;
    const divisions = 10;

    const gridHelper = new THREE.GridHelper(size, divisions);
    // sceneRef.current.add(gridHelper);

    // Set up environment
    const environment = new RoomEnvironment();
    const pmremGenerator = new THREE.PMREMGenerator(renderer);
    sceneRef.current.environment =
      pmremGenerator.fromScene(environment).texture;

    // Load the GLTF model
    const loader = new GLTFLoader();
    loader.load(
      "/model/rosecenter_small._textpetal_new.glb",
      (gltf) => {
        console.log("gltf", gltf.scene);
        const model = gltf.scene;
        model.position.set(0, 0, 0);
        sceneRef.current.add(model);
        modelRef.current = model;
        setLoadModel(true);
      },
      undefined,
      (error) => {
        console.error("An error happened", error);
      }
    );

    // Lighting
    const light = new THREE.DirectionalLight(0xffffff, 1);
    light.position.set(5, 5, 5);
    sceneRef.current.add(light);

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    sceneRef.current.add(ambientLight);

    // Animate function
    const animate = () => {
      requestAnimationFrame(animate);
      orbitRef.current.update();
      rendererRef.current.render(sceneRef.current, cameraRef.current);
    };
    animate();

    // Handle window resize
    const handleResize = () => {
      cameraRef.current.aspect =
        canvasRef.current.clientWidth / canvasRef.current.clientHeight;
      cameraRef.current.updateProjectionMatrix();
      rendererRef.current.setSize(
        canvasRef.current.clientWidth,
        canvasRef.current.clientHeight
      );
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (rendererRef.current) rendererRef.current.dispose();
    };
  };

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      init(); // Call init function to set up Three.js
    }
  }, []);

  useEffect(() => {
    // let timer;
    if (loadModel) {
      // timer = setTimeout(() => {
      loadText(text);
      // }, 200);
    }
    // return () => clearTimeout(timer);
  }, [text, fontFamily, selectedColor, fontSize]);

  useEffect(() => {
    if (colorRoseSelected && loadModel) {
      updateMeshBaseColorTexture(colorRoseSelected,colorCode);
    }
  }, [colorRoseSelected]);

  const handleReset = () => {
    // alert("Reset");
    // updateMeshBaseColorTexture(
    //   "/textures/petalos/base color_albedo/yel_02_FFD52F.png"
    // );
  };

  const handleZoom = () => {
    // alert("zomm");
    // updateMeshBaseColorTexture(
    //   "/textures/petalos/base color_albedo/blu_02_50CFD7 .png"
    // );
  };

  const handleZoom2 = () => {
    // alert("zomm2");
    // updateMeshBaseColorTexture(
    //   "/textures/petalos/base color_albedo/pur_02_A1008E .png"
    // );
  };

  return (
    <>
      <div className="CanvasPart">
        <div className="Logo">
          <img src={logo} alt="logo" />
        </div>

        <div className="Canvas" ref={canvasRef}></div>

        <div className="CanvasPart-controler">
          <button
            className="btn CanvasPart-controler-item"
            onClick={handleReset}
          >
            <img src={bIcon1} alt="reset" width="13px" height="auto" />
          </button>
          <button className="btn CanvasPart-controler-item">
            <img
              src={bIcon2}
              alt="aommOut"
              width="13px"
              height="auto"
              onClick={handleZoom}
            />
          </button>
          <button className="btn CanvasPart-controler-item">
            <img
              src={bIcon3}
              alt="zoomIn"
              width="13px"
              height="auto"
              onClick={handleZoom2}
            />
          </button>
        </div>
      </div>
    </>
  );
}
