import { useContext} from "react";
import icon1 from "../../../../../../assets/Icon1.png";
import FontColorDrop from "./FontColorDrop/FontColorDrop";
import FontColorOffcanvas from "./FontColorOff/FontColorOff";
import FontOffcanvas from "./FontOffcanvas/FontOffcanvas";
import { HomeContext } from "../../../../Context/HomeContext";

export default function TextTab() {
  const {
    selectedColor,
    fontFamily,
    setFontFamily,
    fontSize,
    setFontSize,
    text,
    setText,
    setFontFamilyPath,
  } = useContext(HomeContext);

  const handleTextChange = (e) => {
    if (e.target.value.length <= 14) {
      setText(e.target.value);
    }
  };

  return (
    <>
      <div className="TextTab">
        <textarea
          className="TextTab-textarea"
          style={{
            fontFamily,
            fontSize: `${fontSize}px`,
            color: selectedColor,
            backgroundColor:
              selectedColor === "#FFFFFF" ? "#808080" : "#ffffff",
          }}
          maxLength={14}
          value={text}
          onChange={handleTextChange}
          wrap="hard"
        />

        <div className="TextTab-edit">
          <div className="TextTab-edit-r1">
            <div className="TextTab-edit-r1-fontFamily">
              <label>Font Type</label>
              <FontOffcanvas />
              <select
                value={fontFamily}
                onChange={(e) => {
                  setFontFamilyPath(`/CustomizerFont/${e.target.value}.ttf`);
                  setFontFamily(e.target.value);
                }}
              >
                <option value="Roboto">Roboto</option>
                <option value="Afrah">Afrah</option>
                <option value="Alexbrush">Alexbrush</option>
                <option value="Amazone">Amazone</option>
                <option value="Ananda">Ananda</option>
                <option value="Aristotelicasmallcaps">
                  Aristotelicasmallcaps
                </option>
                <option value="Barcelony">Barcelony</option>
                <option value="Bilboswashcaps">Bilboswashcaps</option>
                <option value="Cabin">Cabin</option>
                <option value="Cardo">Cardo</option>
                <option value="Crimsonpro">Crimsonpro</option>
                <option value="Exo2">Exo2</option>
                <option value="Fabulous">Fabulous</option>
                <option value="Freehand521">Freehand521</option>
                <option value="Gafta">Gafta</option>
                <option value="Heartandsoul">Heartandsoul</option>
                <option value="Hello">Hello</option>
                <option value="Hijrnotes">Hijrnotes</option>
                <option value="Josephsophia">Josephsophia</option>
                <option value="Karla">Karla</option>
                <option value="Kaufmann">Kaufmann</option>
                <option value="Lhandw">Lhandw</option>
                <option value="Lobster">Lobster</option>
                <option value="Lora">Lora</option>
                <option value="Lovelyhome">Lovelyhome</option>
                <option value="Monotypecorsva">Monotypecorsva</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Montserrat2">Montserrat2</option>
                <option value="Neuton">Neuton</option>
                <option value="Opensans">Opensans</option>
                <option value="Pacifico">Pacifico</option>
                <option value="Poppins">Poppins</option>
                <option value="Prozalibre">Prozalibre</option>
                <option value="Quicksand">Quicksand</option>
                <option value="Waterfall">Waterfall</option>
              </select>
            </div>

            <div className="TextTab-edit-r1-fontSize">
              <label htmlFor="">Font Size</label>
              <div className="fontSize-btn">
                <span
                  onClick={() => {
                    setFontSize(fontSize - 2);
                    console.log(fontSize);
                  }}
                >
                  <i className="bi bi-dash"></i>
                </span>
                <img src={icon1} alt="font" />
                <span
                  onClick={() => {
                    setFontSize(fontSize + 2);
                    console.log(fontSize);
                  }}
                >
                  <i className="bi bi-plus"></i>
                </span>
              </div>
            </div>
          </div>

          <div className="TextTab-edit-r2">
            <div className="TextTab-edit-r2-fontColor">
              <label htmlFor="">Font Color</label>
              <FontColorDrop /> {/* desktop */}
              <FontColorOffcanvas /> {/* mobile */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
